<template>
  <section class="bg_color">
    <h2>オーナーランキング</h2>
    <section class="inner">
      <div class="ribon_outline">
        <div class="ttl_ribon rank">
          <p class="ribon_inner"><Today /></p>
        </div>
      </div>
      <h3 class="ttl_main ttl_main--mb"><LastRace /></h3>

      <!--owner-->
      <div v-if="info">
        <div class="box_lst rank graph">
          <h4 class="ttl_lst">
            <p class="inner">上位ランキング変動推移</p>
          </h4>
          <div class="graph_box">
            <chart
              v-if="loaded"
              :chartdata="chartdata"
              :options="chartoptions"
            ></chart>
          </div>
        </div>
        <div class="box_lst rank">
          <h4 class="ttl_lst">
            <p class="inner crown">オーナーランキング</p>
          </h4>
          <!-- <div class="box_lnk">
            <a href="" class="btn_prev nolink">前の10件</a>
            <p class="count">1/10ページ</p>
            <a href="" class="btn_next">次の10件</a>
          </div> -->
          <ul class="lst">
            <li v-for="data in info" :key="data.id">
              <p class="ribon_rank first">
                {{ data.rank }}<span class="small">位</span>
              </p>
              <div class="box_thum">
                <figure>
                  <img
                    :src="data.icon ? data.icon : Icon[0].img"
                    width=""
                    height=""
                    alt=""
                  />
                </figure>
                <p class="name">{{ data.nickname }}</p>
              </div>
              <div class="box_dtl">
                <p class="ttl">現在までの獲得モグー</p>
                <p class="txt point">{{ addComma(data.point) }}</p>
              </div>
              <div class="box_dtl">
                <p class="ttl">今節獲得モグー</p>
                <p class="txt point">{{ addComma(data.current_point) }}</p>
              </div>
              <div class="box_dtl">
                <p class="ttl">登録モーター</p>
                <p class="txt">
                  <span
                    v-for="motors in data.motors"
                    :key="motors.index"
                    class="motor-num"
                    >{{ Number(motors.mno)
                    }}<span class="motor-dot">&#183;</span></span
                  >
                </p>
              </div>
              <div class="box_dtl">
                <router-link
                  :to="{ name: 'RankingOwnerDtl', params: { id: data.id } }"
                  class="btn_dtl btn_dtl--owner"
                  >詳細を見る</router-link
                >
              </div>
            </li>
          </ul>
        </div>
        <!-- <div class="box_lnk box_lnk--paging">
          <a href="" class="btn_prev">前の10件</a>
          <p class="count">1/10ページ</p>
          <a href="" class="btn_next">次の10件</a>
        </div> -->
      </div>
    </section>
  </section>
</template>

<script>
import Mixin from "@/mixins/mixin";
import LastRace from "@/components/LastRaceInfo.vue";
import Today from "@/components/Today.vue";
import { mapGetters } from "vuex";
import Chart from "./MyChart.vue";

export default {
  name: "RankingOwner",
  components: {
    LastRace,
    Today,
    Chart,
  },
  data() {
    return {
      loaded: false,
      chartdata: {
        labels: ["Item 1", "Item 2", "Item 3", "Item 4", "Item 5"],
        // labels: null,
        datasets: [
          {
            backgroundColor: "#f87979",
            borderColor: "#E24729",
            pointBackgroundColor: "#fff",
            data: [1, 2, 3, 4, 5],
            fill: false,
            tension: 0.1,
          },
        ],
      },
      chartoptions: {
        title: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              // X軸
              gridLines: {
                color: "rgba(255, 0, 0, 0.2)",
                zeroLineColor: "black",
              },
              ticks: {
                Max: 35,
                Min: 1,
                stepSize: 1, // ステップサイズを設定
                callback: (value) => {
                  let label = "";
                  if (value === 0) {
                    label = "圏外";
                  } else {
                    label = value + "節";
                  }
                  return label;
                },
              },
            },
          ],
          yAxes: [
            {
              // Y軸
              gridLines: {
                backdropColor: "#231815",
                color: "rgba(0, 0, 255, 0.2)",
                zeroLineColor: "black",
              },
              ticks: {
                reverse: true,
                stepSize: 10,
                position: () =>
                  this.chartdata.datasets &&
                  this.chartdata.datasets[0].data === 0
                    ? "bottom"
                    : "left",
                callback: (value) => {
                  let label = "";
                  if (value === 0) {
                    label = "圏外";
                  } else {
                    label = value + "位";
                  }
                  return label;
                },
              },
            },
          ],
        },
      },
    };
  },
  mixins: [Mixin],
  beforeMount: async function () {
    await this.$store.dispatch("rankingOwnerInfo");
    await this.$store.dispatch("getRankingChart");

    const colorList = [
      "#e3253a",
      "#ffb400",
      "#0068c1",
      "#000000",
      "#00cab4",
      "#00573d",
      "#8e2e5e",
      "#009892",
      "#c7da33",
      "#183a81",
      "#5a3882",
      "#4388ff",
      "#ff8400",
      "#55648c",
      "#f68788",
      "#448844",
      "#785041",
      "#b38b67",
      "#ffc800",
      "#e63083",
    ];
    const result = Object.values(this.chart).map((arr, index) => {
      const labels = arr.map((obj) => obj.nickname);
      const ranks = arr.map((obj) => obj.rank);
      return {
        label: labels[0],
        borderColor: colorList[index % colorList.length],
        pointBackgroundColor: "#fff",
        data: ranks,
        fill: false,
        tension: 0.1,
      };
    });
    this.chartdata.datasets = result;

    // let labels = Array();
    const labels = Object.values(this.chart).map((arr) => {
      const labels = arr.map((obj) => Math.trunc(obj.section));
      return labels;
      // return {
      //   label: labels[0],
      //   backgroundColor: '#f87979',
      //   borderColor: '#E24729',
      //   pointBackgroundColor: '#fff',
      //   data: arr.map((obj) => obj.rank),
      //   fill: false,
      //   tension: 0.1
      // };
    });

    this.chartdata.labels = labels[0];
    this.loaded = true;
  },
  mounted: function () {
    this.$setInterval(() => {
      this.$store.dispatch("rankingOwnerInfo");
      this.$store.dispatch("getRankingChart");
    }, 15000);
  },
  computed: {
    ...mapGetters({
      info: "getRankingOwnerInfo",
      Icon: "getIcon",
      chart: "getChart",
    }),
  },
  beforeUnmount: function () {
    this.$clearAllIntervals();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css" src="@/assets/css/style_parts.css" scoped></style>
<style lang="css" src="@/assets/css/style_ranking.css" scoped></style>
